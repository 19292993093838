import React, { useState, useCallback, useMemo } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import Popup from "../../componentForPages/Popup/Popup"

import {
  Title,
  Dropdown,
  Icon,
  Button,
  ArrowDown,
} from "../../StyleComponents/styles"

import ScrollWindowList from "./components/scrollReactWindow"

const DropDownList = React.lazy(() => import("./components/DropDown"))

const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/emoji-copy-and-paste",
  "/text-art",
]

function EmojiNames(props) {
  const { node, allPages } = props.pageContext

  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    e.target.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
      setTimeout(function () {
        setShow(false)
      }, 1500)
    }
  }, [])

  return (
    <Layout location={props.location}>
      <SEO
        title={`${node.name} Character Entity `}
        description={`${node.name} Entity Characters, Decimal, Hexadecimal, and copy paste ${node.name} Characters.`}
        keywords={[
          `${node.name} Entity, ${node.name} Characters, ${node.name} Decimal , ${node.name} Hexadecimal`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Utility Tools", link: "/utility-tools/" },
            { name: "Html Entity", link: "/html-entity/" },
            {
              name: node.name,
              link: `/html-entity/${node.name
                .split(" ")
                .join("-")
                .toLowerCase()}-entity/`,
            },
          ]
        }, [node.name])}
      />
      <section className="container">
        <Title id="listOfEmoji">{node.name}</Title>
        {show && (
          <Popup
            title={emoji + " have been copied"}
            emoji={emoji}
            close={() => setShow(false)}
          />
        )}
        <br />
        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Characters Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownList list={allPages} />
            </React.Suspense>
          )}
        </Dropdown>
        <br />
        <ScrollWindowList pageItems={node.code} mycopy={mycopy} />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmojiNames
